import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Layout from "../components/new/Layout";
import ButtonLink from "../components/new/ButtonLink";
import Heading from "../components/new/typography/Heading";

import backgroundImage from "../images/Dxtra–404.jpeg";
import backgroundImageMobile from "../images/Dxtra-404-Mobile.png";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backgroundImageMobile});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    background-image: url(${backgroundImage});
  }
`;

const Content = styled.div`
  max-width: 70%;
  padding: 20px;
  color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding: 100px;
  }
`;

const NotFoundPage = () => (
  <>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={`Global Marketing Collective | Page Not Found`}
      titleTemplate={`%s | IPG - DXTRA`}
      meta={[
        {
          name: `description`,
          content: "The page you were looking for does not exist",
        },
        {
          property: `og:title`,
          content: `404 | IPG - DXTRA`,
        },
        {
          property: `og:description`,
          content: "The page you were looking for does not exist",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "IPG Dxtra",
        },
        {
          name: `twitter:title`,
          content: "404 | IPG - DXTRA",
        },
        {
          name: `twitter:description`,
          content: "The page you were looking for does not exist",
        },
      ]}
    />
    <Layout navigationTransparent={false}>
      <Container>
        <Content>
          <Heading size="xl" light margin="0 0 xxl">
            Our 404 page. Now in a black hole nowhere.
          </Heading>
          <ButtonLink to="/" title="Return Home Fast" variant="back" />
        </Content>
      </Container>
    </Layout>
  </>
);

export default NotFoundPage;
